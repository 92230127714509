import logo from './logo.png';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p style={{ fontWeight: '700' }}>
                    Coming Soon ...
                </p>
                {/* <p> */}
                <span className='contact'> <a href='mailto:info@desertbeeshoney.com'> info@desertbeeshoney.com</a></span>

                <span className='contact'> <a href='tel:+91 90236 46449'> +91 90236 46449</a> </span>
                {/* </p> */}
            </header>
        </div>
    );
}

export default App;
